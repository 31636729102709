import React from 'react'
import {graphql} from 'gatsby'
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Breadcrumbs from '../../components/sections/Breadcrumbs';

export default ({data}) => {
    const {page} = data
    return (
        <Layout>
            <SEO 
              title={page.seoInfo.title}
              description={page.seoInfo.metaDescription.metaDescription}
              robots={page.seoInfo.metaRobots}
              ogDescription={page.seoInfo.openGraphDescription}
              twitterDescription={page.seoInfo.twitterCardDescription}
              twitterTitle={page.seoInfo.twitterCardTitle}
              twitterImage={page.seoInfo.twitterCardImage}
              ogTitle={page.seoInfo.openGraphTitle}
              twitterImage={page.seoInfo.twitterImage}
              ogImage={page.seoInfo.openGraphImage.localFile.url}
            />
            <Breadcrumbs />
            <div className="blog mx-auto container px-4 mb-16 md:mb-24">
                {renderRichText(page.content)}
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($id: String!) {
        page: contentfulPage(id: {eq: $id}) {
            title
            content {
                raw
            }
            seoInfo {
              canonicalUrl
              metaDescription {
                metaDescription
              }
              metaRobots
              openGraphDescription
              openGraphTitle
              title
              twitterCardImage
              twitterCardDescription
              twitterCardTitle
              openGraphImage {
                localFile {
                  url
                }
              }
            }
        }
    }
`